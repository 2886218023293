import "../styles/globals.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import AppStyles from "../ui/StyleComponent";
function MyApp({ Component, pageProps, router }: AppProps) {
  const { pathname } = router;
  const isUFB = pathname.includes("/ufb");

  return (
    <>
      <Head>
        <meta name="description" content="What to Watch" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover"
        />
        <link rel="icon" href="/favicon.png" />
      </Head>
      <Script id="vconsole">
        {`if (window.location.href.indexOf('__debug__') > 0) {
        var script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.src = 'https://unpkg.com/vconsole/dist/vconsole.min.js';
        script.onload = () => {
          var vConsole = new window.VConsole();
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      }`}
      </Script>
      <AppStyles isUFB={isUFB} />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
